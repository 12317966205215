import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import EventList from './components/EventList';
import axiosInstance from './utils/axios';
import './App.css';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      response => {
        console.log(response)
        return response
      },
      error => {
        console.log(error.response)
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate("/");
        }
        return Promise.reject(error);
      }
    );
  }, [navigate]);

  return (
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route path="/events" element={<EventList/>} />
      </Routes>
  );
}

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
