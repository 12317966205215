import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import logo from '../assets/tribe.png';
import '../styles/Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post('/api/users/login', { email, password }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      localStorage.setItem('token', res.data.token);
      setLogin(true)
    } catch (err) {
      console.log('err')
      console.log(err)
      setError('Identifiants incorrects');
    }
  }

  return (
    <div className="login-container">
    {login && navigate("/events")}
      <img src={logo} alt="Logo" className="logo" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label><br />
          <input
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label><br />
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit" className="submit-button">Se connecter</button>
      </form>
    </div>
  );
}

export default Login;
