import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axios';
import '../styles/EventList.css';
import { ToastContainer, toast } from 'react-toastify';
import ToggleButton from './ToggleButton';
import Spinner from './Spinner';
import BlocksSelect from './Select';
import 'react-toastify/dist/ReactToastify.css';

const ALL_ENTITIES = 'All';

function EventList() {
  const [isChecked, setChecked] = useState(() => {
    const saved = localStorage.getItem('isChecked');
    return saved === null ? false : JSON.parse(saved);
  });

  const [vgAccounts, setVgAccounts] = useState([]);

  const [blocks, setBlocks] = useState({});

  const [selectedVgAccount, setSelectedVgAccount] = useState(() => {
    const saved = localStorage.getItem('selectedVgAccount');
    return saved === null ? ALL_ENTITIES : JSON.parse(saved);
  });

  const [isMevModeActivate, setMevModeActivation] = useState(false);

  const [displayInactiveEvent, setDisplayInactiveEvent] = useState(() => {
    const saved = localStorage.getItem('displayInactiveEvent');
    return saved === null ? true : JSON.parse(saved);
  });

  const [activeEvents, setActiveEvents] = useState([]);
  const [inactiveEvents, setInactiveEvents] = useState([]);
  const [selectedEventId, setSelectedEvent] = useState(null);
  const [optionsState, setOptionState] = useState({});

  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);
  const [showCompet, isShowCompet] = useState(false);
  const [subEvent, setSubEvents] = useState([]);

  const [sortDirection, setSortDirection] = useState('asc');
  const [sortKey, setSortKey] = useState('eventName');

  const [sortSubKey, setSortSubKey] = useState('bloc');
  const [sortSubDirection, setSortSubDirection] = useState('asc');

  const [searchText, setSearchText] = useState('');

  const filteredEvents = (events) => events.filter((event) =>
    event.eventName.toLowerCase().includes(searchText.toLowerCase())
  );

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleToggleButton = () => {
    setChecked(!isChecked);
  };

  const handleMevModeButton = async () => {
    try {
      setIsSpinnerLoading(true);
      const { status, data } = await axiosInstance.put('/api/event-listing/mevMode', { mode : !isMevModeActivate ? 'on' : 'off' }, {
          headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
      });
      if (status !== 200) {
        throw new Error (data.message || 'An error occurred, please try again later');
      }
      setMevModeActivation(data.mevMode);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setIsSpinnerLoading(false);
    }
  };

  const displayInactiveEventToggle = () => {
    setDisplayInactiveEvent(!displayInactiveEvent);
  };

  const sortEvents = (events) => {
    return events.slice().sort((a, b) => {
      let dataA = a[sortKey];
      let dataB = b[sortKey];

      if (sortKey === 'eventDate') {
        dataA = new Date(dataA);
        dataB = new Date(dataB);
      }
      if (sortDirection === 'asc') {
        return dataA > dataB ? 1 : -1;
      } else {
        return dataA < dataB ? 1 : -1;
      }
    });
  };

  const handleSortChange = (key) => {
    if (sortKey === key) {
      toggleSortDirection();
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const sortSubEvents = (subEvents) => {
    return subEvents.slice().sort((a, b) => {
      let dataA = a[sortSubKey];
      let dataB = b[sortSubKey];

      if (sortSubDirection === 'asc') {
        return dataA > dataB ? 1 : -1;
      } else {
        return dataA < dataB ? 1 : -1;
      }
    });
  };

  const handleSubSortChange = (key) => {
    if (sortSubKey === key) {
      setSortSubDirection(sortSubDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortSubKey(key);
      setSortSubDirection('asc');  // Réinitialise la direction du tri lors du changement de clé
    }
  };

  const token = localStorage.getItem('token');

  useEffect(() => {
    localStorage.setItem('isChecked', JSON.stringify(isChecked));
  }, [isChecked]);

  useEffect(() => {
    localStorage.setItem('selectedVgAccount', JSON.stringify(selectedVgAccount));
  }, [selectedVgAccount]);

  useEffect(() => {
    axiosInstance.get('/api/event-listing/vgAccounts', {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      }
    }).then(result => {
      setVgAccounts([ALL_ENTITIES, ...result.data]);
    });
  }, [token]);

  useEffect(() => {
    axiosInstance.get('/api/event-listing/mevMode', {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': token,
      }
    }).then(result => {
      console.log('result');
      console.log(result.data.mevMode);
      setMevModeActivation(result.data.mevMode);
    });
  }, [isMevModeActivate, token]);

  useEffect(() => {
    localStorage.setItem('displayInactiveEvent', JSON.stringify(displayInactiveEvent));
  }, [displayInactiveEvent]);

  useEffect(() => {
    const path = `/api/event-listing${selectedVgAccount !== ALL_ENTITIES ? '?vgAccount=' + selectedVgAccount : ''}`;
    axiosInstance.get(path, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    })
      .then(res => {
        if (res.status !== 200) {
          throw new Error(res?.data?.message || 'An error occurs on server side');
        }
        const data = res.data;
        const filteredEvents = data.reduce((acc, elem) => {
          if (elem.lpType === 'INACTIVE') acc.inactive[elem.viagogoId] = elem;
          else {
            if (!acc.active[elem.viagogoEventId]) acc.active[elem.viagogoEventId] = {...elem, listings: {}};
            acc.active[elem.viagogoEventId].listings[elem.viagogoId] = elem;
          }
          return acc;
        }, { active: {}, inactive: {} });
        console.log(filteredEvents);
        setInactiveEvents(filteredEvents.inactive);
        setActiveEvents(filteredEvents.active);
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER
        });
      });
  }, [token, selectedVgAccount]);


  async function handleRowClick(event) {
    try {
      isShowCompet(true);
      setIsSpinnerLoading(true);
      const { data } = await axiosInstance.get(`/api/event-listing/eventId/${event.viagogoEventId}?getCompetitiveInfo=${isChecked}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
      });
      if (data.error) {
        console.error(data.error)
        toast.error(data.error, {
          position: toast.POSITION.TOP_CENTER
        });
        isShowCompet(false);
      }

      if (selectedEventId === event.id) {
        return setSelectedEvent(null);
      }
      setSubEvents(data.listings);
      setSelectedEvent(event.id);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setIsSpinnerLoading(false);
    }
  }

  async function lpEvent(event) {
    try {
      const resp = await axiosInstance.put(`/api/event-listing/${event.viagogoId}/activate`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
      })
      if (resp.status !== 200) {
        throw new Error(resp?.data?.message || 'An error occurs on server side');
      }
      const data = resp.data;
      const formatedData = { [data.viagogoId]: data };
      const updatedActiveEvents = {...activeEvents};
      const updatedInactiveEvents = { ...inactiveEvents };
      if (data.lpType === 'INACTIVE') {
        delete updatedActiveEvents[data.viagogoEventId].listings[data.viagogoId];
        if (Object.keys(updatedActiveEvents[data.viagogoEventId].listings).length === 0) delete updatedActiveEvents[data.viagogoEventId];
        setActiveEvents(updatedActiveEvents);
        setInactiveEvents({...updatedInactiveEvents, ...formatedData});
      } else {
        delete updatedInactiveEvents[data.viagogoId];
        setInactiveEvents(updatedInactiveEvents);
        if (!updatedActiveEvents[data.viagogoEventId]) updatedActiveEvents[data.viagogoEventId] = {...data, listings: {}};
        updatedActiveEvents[data.viagogoEventId].listings[data.viagogoId] = data;
        setActiveEvents(updatedActiveEvents);
      }
      toast.success(`Event "${event.eventName}" - Id [${event.viagogoId}] Activated !`, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log(error)
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  function updatePrice(event, price, id) {
    price = parseFloat(price);
    if (isNaN(price)) {
      return toast.error('Hmm, valeur incorrecte ...', {
        position: toast.POSITION.TOP_CENTER
      });
    }
    if (/min_/.test(id) && event.minPrice !== price) {
      event.minPrice = price;
      update(event);
    }
    if (/max_/.test(id) && event.maxPrice !== price) {
      event.maxPrice = price;
      update(event);
    }
  }

  async function update(event) {
    try {
      const resp = await axiosInstance.put(`/api/event-listing/${event.viagogoId}`, {
        viagogoId: event.viagogoId,
        minPrice: event.minPrice,
        maxPrice: event.maxPrice,
        viagogoEventId: event.viagogoEventId,
        lpPriority: event.lpPriority,
        lpType: event.lpType
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        }
      })
      if (resp.status !== 200) {
        throw new Error(resp?.data?.message || 'An error occurs on server side');
      }
      const data = resp.data;
      const formatedData = { [data.viagogoId]: data };
      const updatedActiveEvents = {...activeEvents};
      const updatedInactiveEvents = { ...inactiveEvents };
      if (data.lpType === 'INACTIVE') {
        setInactiveEvents({...updatedInactiveEvents, ...formatedData});
      } else {
        if (!updatedActiveEvents[data.viagogoEventId]) updatedActiveEvents[data.viagogoEventId] = {...data, listings: {}};
        updatedActiveEvents[data.viagogoEventId].listings[data.viagogoId] = data;
        setActiveEvents(updatedActiveEvents);
      }
      toast.success(`Update Success for "${event.eventName}" - Id [${event.viagogoId}] !`, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log(error)
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  function selectLpPrority(priority, listing) {
    // setOptionState(priority);
    setOptionState((prevState) => ({
      ...prevState,
      [listing.viagogoId]: priority,
    }));
    axiosInstance.put(`/api/event-listing/${listing.viagogoId}`, {
      viagogoId: listing.viagogoId,
      minPrice: listing.minPrice,
      maxPrice: listing.maxPrice,
      viagogoEventId: listing.viagogoEventId,
      lpPriority: priority,
      lpType: listing.lpType
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    }).then(response => {
      toast.success(`Priority changed to ${priority}`, {
        position: toast.POSITION.TOP_CENTER
      });
    }).catch(error => {
      toast.error(`Error on change priority to ${priority} - with message ${error}`, {
        position: toast.POSITION.TOP_CENTER
      });
    })
    console.log(priority)
    console.log(listing)
  }

  return (
    <div>
      {isSpinnerLoading && <Spinner />}
      <h1>Leader Price</h1><hr/>
      <input
        type="text"
        placeholder="Rechercher un événement"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div style={{
          position: 'absolute',
          right: '20px',
        }}>
        Entity: <select style={{
            minWidth: '100px',
            height: '30px'
          }}
          value={selectedVgAccount}
          onChange={(e) => setSelectedVgAccount(e.target.value)}
          >
          {vgAccounts.map((account, index) => (
            <option key={index} value={account}>
              {account.split('@').shift().toUpperCase()}
            </option>
          ))}
        </select>
      </div>
      <div>
        <br/>
        <h4 style={{ display: 'flex', alignItems: 'center' }}><span style={{ fontSize: '18px', marginRight: '10px', lineHeight: '1.5', color: isMevModeActivate ? 'orange' : 'black' }}>MODE MEV</span> <ToggleButton onToggle={handleMevModeButton} isChecked={isMevModeActivate} /></h4>
        <h4 style={{ display: 'flex', alignItems: 'center' }}><span style={{ fontSize: '18px', marginRight: '10px', lineHeight: '1.5' }}>Listing comptetitive check</span> <ToggleButton onToggle={handleToggleButton} isChecked={isChecked} /></h4>
        <h4 style={{ display: 'flex', alignItems: 'center' }}><span style={{ fontSize: '18px', marginRight: '10px', lineHeight: '1.5' }}>Display inactive events</span> <ToggleButton onToggle={displayInactiveEventToggle} isChecked={displayInactiveEvent} /></h4>
      </div>
      {displayInactiveEvent && (
        <>
        <h2>Inactive listings on LP</h2>
        <div id="inactive">
        <table>
          <thead className="inactive">
            <tr>
              <th onClick={() => handleSortChange('eventName')}>Event
                {sortKey === 'eventName' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('venue')}>Lieu
                {sortKey === 'venue' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('eventDate')}>Date
                {sortKey === 'eventDate' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('bloc')}>Bloc
                {sortKey === 'bloc' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th>Similar Blocks</th>
              <th onClick={() => handleSortChange('availableTickets')}>Nb billets
                {sortKey === 'availableTickets' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('minPrice')}>Min
                {sortKey === 'minPrice' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('maxPrice')}>Max
                {sortKey === 'maxPrice' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('currentPrice')}>Current
                {sortKey === 'currentPrice' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('vf')}>VF
                {sortKey === 'vf' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th onClick={() => handleSortChange('vgAccount')}>VG Account
                {sortKey === 'vgAccount' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
              </th>
              <th>Activer LP</th>
            </tr>
          </thead>
          <tbody>
            {displayInactiveEvent && inactiveEvents && filteredEvents(sortEvents(Object.values(inactiveEvents))).map((event, index) => (
              <React.Fragment key={event.viagogoId}>
                <tr style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                  {/* <td>{event.viagogoEventId}</td> */}
                  <td>{event.eventName}<span className='eventId'><br />{`[${event.viagogoEventId}]`}<br />{`[${event.viagogoId}]`}</span></td>
                  <td>{event.venue}</td>
                  <td>{event.eventDate.split('T').shift().split('-').reverse().join('/')}</td>
                  <td>{event.bloc}</td>
                  <td>{<BlocksSelect blocks={blocks} setBlocks={setBlocks} eventId={event.viagogoEventId} listingId={event.viagogoId} currentBlocks={event.similarBlocks?.split('|') || null}/>}</td>
                  <td>{event.availableTickets}</td>
                  <td><input id={`min_${event.viagogoId}`} className='prices' defaultValue={event.minPrice} onBlur={(input) => updatePrice(event, input.target.value, input.target.id)}/></td>
                  <td><input id={`max_${event.viagogoId}`} className='prices' defaultValue={event.maxPrice} onBlur={(input) => updatePrice(event, input.target.value, input.target.id)}/></td>
                  <td>{event.currentPrice}</td>
                  <td>{event.vf}</td>
                  <td>{event.vgAccount.split('@').shift().toUpperCase()}</td>
                  <td><button className="inactive" onClick={() => lpEvent(event)}>Activate LP</button></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        </div>
        </>
      )}
      <h2>Active listings on LP</h2>
      <div id="active">
      <table>
        <thead className="active">
          <tr>
            {/* <th>Id Manif</th> */}
            <th onClick={() => handleSortChange('eventName')}>Event
              {sortKey === 'eventName' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
            </th>
            <th onClick={() => handleSortChange('eventDate')}>Date
              {sortKey === 'eventDate' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
            </th>
            <th onClick={() => handleSortChange('venue')}>Lieu
              {sortKey === 'venue' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
            </th>
            <th onClick={() => handleSortChange('availableTickets')}>Nb billets
              {sortKey === 'availableTickets' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
            </th>
            <th onClick={() => handleSortChange('soldTickets')}>Nb Ventes
              {sortKey === 'soldTickets' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
            </th>
            <th onClick={() => handleSortChange('vf')}>VF
              {sortKey === 'vf' && (sortDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
            </th>
          </tr>
        </thead>
        <tbody>
          {activeEvents && filteredEvents(sortEvents(Object.values(activeEvents))).map((event, index) => (
            <React.Fragment key={event.viagogoId}>
              <tr style={{ backgroundColor: isMevModeActivate ? (index % 2 === 0 ? '#A9EAFE' : 'white') : (index % 2 === 0 ? '#f2f2f2' : 'white') }}  onClick={() => !isSpinnerLoading && handleRowClick(event)}>
                {/* <td>{event.viagogoEventId}</td> */}
                <td>{event.eventName} <span className='eventId'>{`[${event.viagogoEventId}]`}</span></td>
                <td>{event.eventDate?.split('T').shift().split('-').reverse().join('/')}</td>
                <td>{event.venue}</td>
                <td>{Object.values(event.listings).reduce((acc, elem) => {
                  return acc + elem.availableTickets
                 }, 0)}</td>
                <td>{Object.values(event.listings).reduce((acc, elem) => {
                  return acc + elem.soldTickets
                 }, 0)}</td>
                <td>{event.vf}</td>
              </tr>
              {selectedEventId === event.id && ((
                <tr>
                  <td colSpan={10}>
                    <table>
                    <thead className="subactive">
                        <tr>
                          {/* <th>Id Manif</th> */}
                          <th>VG Account</th>
                          <th>ID</th>
                          <th onClick={() => handleSubSortChange('bloc')}>Catégorie
                            {sortSubKey === 'bloc' && (sortSubDirection === 'asc' ? (<span> &darr;</span>) : (<span> &uarr;</span>))}
                          </th>
                          <th>Similar Blocks</th>
                          <th>Nb Billets</th>
                          <th>VF</th>
                          <th>Min</th>
                          <th>Max</th>
                          <th>Current</th>
                          <th>LP MODE</th>
                          { isChecked && showCompet && <th>COMPET</th> }
                          <th>COMMENTAIRE</th>
                          <th>Couper LP</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subEvent && sortSubEvents(subEvent).map((listing, index) => (
                        <React.Fragment key={event.id}>
                        <tr>
                          <td>{listing.vgAccount.split('@').shift().toUpperCase()}</td>
                          <td>{listing.viagogoId}</td>
                          <td>{listing.bloc}</td>
                          <td>{<BlocksSelect blocks={blocks} setBlocks={setBlocks} eventId={listing.viagogoEventId} listingId={listing.viagogoId} currentBlocks={listing.similarBlocks?.split('|') || null}/>}</td>
                          <td>{listing.availableTickets}</td>
                          <td>{listing.vf}</td>
                          <td><input id={`min_${listing.viagogoId}`} className='prices' defaultValue={listing.minPrice} onBlur={(input) => updatePrice(listing, input.target.value, input.target.id)}/></td>
                          <td><input id={`max_${listing.viagogoId}`} className='prices' defaultValue={listing.maxPrice} onBlur={(input) => updatePrice(listing, input.target.value, input.target.id)}/></td>
                          <td>{listing.currentPrice}</td>
                          <td><select onChange={(event) => selectLpPrority(event.target.value, listing)} value={optionsState[listing.viagogoId] || listing.lpPriority}>
                            <option value="MEV">MEV</option>
                            <option value="HOT">HOT</option>
                            <option value="NORMAL">NORMAL</option>
                            <option value="CM">CM</option>
                          </select></td>
                          { isChecked && showCompet && <td><button style={{ backgroundColor: listing.lpCompetitive ? '#2ECC71' : '#E74C3C' }}>{listing.lpCompetitive ? 'OUI' : 'NON'}</button></td> }
                          <td><input id={listing.viagogoId} defaultValue={listing.comment} /></td>
                          <td><button className="active" onClick={() => lpEvent(listing)}>CUT LP</button></td>
                        </tr>
                        </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <ToastContainer />
      </div>
    </div>
  );
}

export default EventList;