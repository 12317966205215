import React from 'react';

import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axios';


const BlocksSelect = ({ blocks, setBlocks, eventId, listingId, currentBlocks }) => {
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(currentBlocks?.map(elem => ({ value: elem, label: elem })) || null);

  const handleChange = (val) => {
    setValue(val);
    const similarBlocks = val.map(elem => elem.value).join('|');
    return updateBlocks({ listingId, similarBlocks });
  }

  async function updateBlocks({ listingId, similarBlocks }) {
    try {
      await axiosInstance.put(`/api/event-listing/${listingId}/similarBlocks`,
        {
          similarBlocks: similarBlocks,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token'),
          }
        }
      );
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function getBlocks({ blocks, setBlocks, eventId }) {
    try {
      setIsLoading(true);
      const { data, status } = await axiosInstance.get(`api/vgApi/eventDetails?eventLink=https://api.viagogo.prod/v2/sellerevents/${eventId}&ticketType=ETicket`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('token'),
        }
      });
      if (status !== 200) {
        toast.error(data.message || JSON.stringify(data), {
          position: toast.POSITION.TOP_CENTER
        });
      }
  
      let cats = data?.categories;
      if (!cats || cats.lenght === 0) {
        cats = [];
      }
  
      const eventBlocks = cats?.map(elem => elem.label);
      setBlocks({ ...blocks, [eventId]: eventBlocks || [] });
      setOptions(eventBlocks.map(elem => ({ value: elem, label: elem })));
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setIsLoading(false);
    }
  }
  
  const promiseOptions = ({ blocks, setBlocks, eventId }) => {
    if (blocks[eventId] && blocks[eventId].length > 0) {
      return setOptions(blocks[eventId].map(elem => ({ value: elem, label: elem })));
    }
      return getBlocks({ eventId, setBlocks });
  }
  return (
    <Select
      error
      isMulti
      options={options}
      value={value}
      onFocus={() => promiseOptions({ blocks, setBlocks, eventId })}
      onChange={handleChange}
      isLoading={isLoading}
    />
  );
}

export default BlocksSelect;
