import React from 'react';
import Switch from 'react-switch';

const ToggleButton = ({ onToggle, isChecked }) => {
  return (
    <Switch
      onChange={onToggle}
      checked={isChecked}
      uncheckedIcon={false}
      checkedIcon={false}
    />
  );
};

export default ToggleButton;